/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    GCPVIPAllocationMode,
    IGCPVIPAllocation,
    IGCPVIPILB,
    IGCPVIPRoutes,
} from 'generated-types';

import { GCPVIPAllocation } from 'object-types';

type TGcpVipAllocationPartial = Omit<IGCPVIPAllocation, 'routes' | 'ilb'>;

interface IGcpVipAllocation extends TGcpVipAllocationPartial {
    routes?: MessageItem<IGCPVIPRoutes>;
    ilb?: MessageItem<IGCPVIPILB>;
}

/**
 * @description
 *
 *   GCP VipAllocation MessageItem.
 *
 * @author Sarthak kapoor
 */
export class GCPVipAllocationConfigItem extends MessageItem<IGcpVipAllocation> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: GCPVIPAllocation,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Invoked when user changes the VIPAllocation mode from UI.
     */
    public onAllocationModeChange(): void {
        const { config } = this;

        if (config.mode === GCPVIPAllocationMode.ILB) {
            delete config.routes;

            this.safeSetNewChildByField('ilb');
        } else {
            delete config.ilb;

            this.safeSetNewChildByField('routes');
        }
    }
}
