/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module PoolModule */

import {
    copy,
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper';

import {
    findWhere,
    isArray,
    isEmpty,
    some,
} from 'underscore';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    ObjectTypeItem,
} from 'ajs/modules/data-model/factories/object-type-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { StringService } from 'ajs/modules/core/services/string-service';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    AviPermissionResource,
    HttpReselectRespCodeBlock,
    IHTTPStatusRange,
    IMetricsQuery,
    INsxtTier1,
    IPool,
    IServer,
    LbAlgorithm,
    PoolType,
} from 'generated-types';

import { TStringRow } from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { RoleFilterMatchLabelConfigItem } from
    'ajs/modules/data-model/factories/role-filter-match-label.config-item.factory';

import { withEditChildMessageItemMixin } from
    'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { TItemConfig } from 'ajs/modules/data-model/factories';

import {
    Component,
    Type,
} from '@angular/core';

import {
    FailActionConfigItem,
    FailActionHttpLocalResponseConfigItem,
    FailActionHttpRedirectConfigItem,
    HTTPServerReselectConfigItem,
    PlacementNetworkConfigItem,
    ServerConfigItem,
} from '.';

type TPoolConfigPartial = Omit<IPool, 'placement_networks' | 'fail_action'
| 'markers' | 'servers' | 'server_reselect'>;
interface IPoolConfig extends TPoolConfigPartial {
    placement_networks: RepeatedMessageItem<PlacementNetworkConfigItem>,
    fail_action: FailActionConfigItem,
    markers: RepeatedMessageItem<RoleFilterMatchLabelConfigItem>,
    servers: RepeatedMessageItem<ServerConfigItem>,
    server_reselect: HTTPServerReselectConfigItem,
}
interface IPoolData {
    config: IPoolConfig;
    virtualservices: string[];
    pool_group_refs: string[];
}

export interface IAutoscaleGroupsApiResponse {
    group_name: string,
    name: string,
}

export interface ICloudVrfContext {
    vrf_ref: string,
    cloud_ref: string,
}

interface IDetailsPageStateParams {
    id?: string | null;
    poolId?: string,
    vsId?: string,
}

interface IExtendedServer extends IServer {
    uuid?: string;
    default_server_port?: number;
}

const NSXT_BASE_API = '/api/nsxt';
const TIER1S_API = `${NSXT_BASE_API}/tier1s`;

/**
 * @description Pool item.
 * @author Nisar Nadaf
 */
export class Pool extends withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public apiResponseCache: any;
    public getConfig: () => IPoolConfig;
    public data: IPoolData;
    protected readonly stringService: StringService;
    private vsId: string;

    /**
     * VirtualService item. TODO: change "any" to the VirtualService class once
     * it's been converted to ts
     */
    private vs: any;

    /**
     * Server item. TODO: change "any" to the Server class once it's been converted to ts
     */
    private Server: any;

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private httpWrapper: HttpWrapper;

    constructor(args: any = {}) {
        const extendedArgs = {
            objectName: 'pool',
            objectType: 'Pool',
            permissionName: AviPermissionResource.PERMISSION_POOL,
            vs: null,
            vsId: '',
            params: {
                include_name: true,
            },
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.apiResponseCache = {
            autoscaleGroupServers: {},
        };

        const {
            vs,
            vsId,
        } = args;

        if (vs) {
            this.vs = vs;
            this.vsId = vs.id;
        } else if (vsId) {
            this.vsId = vsId;
        }

        this.Server = this.getAjsDependency_('Server');
        this.stringService = this.getAjsDependency_('stringService');

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Fetches NSX-T Tier1s.
     */
    public async fetchTier1s(): Promise<INsxtTier1[]> {
        if (!this.config.cloud_ref && !this.config.vrf_ref) {
            return [];
        }

        const {
            nsxt_url: nsxtUrl,
            nsxt_credentials_ref: credentialsRef,
        } = this.config;

        const payload = {
            credentials_uuid: this.stringService.slug(credentialsRef),
            host: nsxtUrl,
        };

        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = {
                url: TIER1S_API,
                method: HttpMethod.POST,
                data: payload,
                requestId: 'tier1s',
            };

            const response = await this.httpWrapper.request(requestConfig);
            const { resource = {} } = response.data;
            const { nsxt_tier1routers: tier1Routers = [] } = resource;

            return tier1Routers;
        } catch (errors) {
            this.errors = errors.data;

            return [];
        } finally {
            this.busy = false;
        }
    }

    /**
     * Opens edit server modal.
     */
    public editServer(server: ServerConfigItem): void {
        this.editChildMessageItem({
            field: 'servers',
            messageItem: server,
        });
    }

    /**
     * @override
     * Method used to import lazy loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        /* eslint-disable-next-line max-len */
        const {
            PoolModalComponent,
        } = await import(
            /* webpackChunkName: "pool-modal" */
            'ng/lazy-loaded-components/modals/pool-modal/pool-modal.component'
        );

        return PoolModalComponent as Type<Component>;
    }

    /**
     * Returns the list of placement network config items.
     */
    public get placementNetworks(): RepeatedMessageItem<PlacementNetworkConfigItem> {
        return this.config.placement_networks;
    }

    /**
     * Returns the list of Health monitors.
     */
    public get healthMonitors(): TStringRow[] {
        return this.config.health_monitor_refs;
    }

    /**
     * Sets Codes property of server response code.
     */
    public set serverResponseCodes(codes: number[]) {
        this.config.server_reselect.serverResponseCodes = codes;
    }

    /**
     * Sets Ranges property of server response code.
     */
    public set serverResponseCodeRanges(ranges: IHTTPStatusRange[]) {
        this.config.server_reselect.serverResponseCodeRanges = ranges;
    }

    /**
     * Sets Code block property of server response code.
     */
    public set serverResponseCodeBlock(block: HttpReselectRespCodeBlock[]) {
        this.config.server_reselect.serverResponseCodeBlock = block;
    }

    /**
     * Getter for enabled property from HTTPServerReselectConfigItem.
     */
    public get httpServerReselectEnabled(): boolean {
        return this.config.server_reselect.enabled;
    }

    /**
     * setter for enabled property from HTTPServerReselectConfigItem.
     */
    public set httpServerReselectEnabled(enabled: boolean) {
        this.config.server_reselect.enabled = enabled;
    }

    /**
     * Add health monitor entry.
     */
    public addHealthMonitor(): void {
        this.healthMonitors.push({
            value: '',
        });
    }

    /**
     * Delete Health monitor entry.
     */
    public deleteHealthMonitor(healthMonitor: TStringRow): void {
        const index = this.healthMonitors.indexOf(healthMonitor);

        this.healthMonitors.splice(index, 1);
    }

    /**
     * Returns fail action of local response type.
     */
    public get failActionLocalResponse(): FailActionHttpLocalResponseConfigItem {
        return this.failAction.localResponse;
    }

    /**
     * Returns fail action of redirect type.
     */
    public get failActionRedirect(): FailActionHttpRedirectConfigItem {
        return this.failAction.httpRedirect;
    }

    /**
     * Returns Fail action config item.
     */
    public get failAction(): FailActionConfigItem {
        return this.config.fail_action;
    }

    /**
     * Returns file Item for fail action of local response type.
     */
    public get failActionLocalResponseFile(): any {
        return this.failAction.localResponse.config.file;
    }

    /**
     * Sets the value of ignore_server_port depending on
     * lb_algorithm and use_service_port values.
     */
    public manageIgnoreServerPort(): void {
        const {
            use_service_port: useServicePort,
            lb_algorithm: lbAlgorithm,
        } = this.config;

        if (!useServicePort && !(lbAlgorithm === LbAlgorithm.LB_ALGORITHM_CONSISTENT_HASH)) {
            this.config.ignore_server_port = false;
        }

        if (!useServicePort) {
            this.config.use_service_ssl_mode = false;
        }
    }

    /**
     * Returns the cloud_ref configured on the PoolGroup.
     */
    public getCloudRef(): string {
        const { cloud_ref: cloudRef } = this.getConfig() || {};

        return cloudRef;
    }

    /**
     * Returns the VRF context ref configured for this pool.
     */
    public getVRFContextRef(): string {
        const { vrf_ref: vrfRef } = this.getConfig();

        return vrfRef || '';
    }

    public dataToSave(): IPool {
        const config = super.dataToSave();

        const { health_monitor_refs: monitorList } = config;

        const networksHash = {};
        const networks: string[] = [];

        config.health_monitor_refs = monitorList?.map((healthMonitor: TStringRow) => {
            return healthMonitor.value || healthMonitor;
        });

        config.servers?.forEach((server: IExtendedServer) => {
            if (server.port === null) {
                delete server.port;
            }

            const netId = server.nw_ref;

            if (netId && !(netId in networksHash)) {
                networks.push(netId);
                networksHash[netId] = true;
            }

            // for servers provided by ServerCollection
            delete server.uuid;
            delete server.default_server_port;
        });

        if (config.pool_type === PoolType.POOL_TYPE_OAUTH) {
            this.removeGenericPoolProperties(config);
        }

        /**
         * Need not to pass the servers in case of Security group.
         * They are added by backend automatically.
         */
        if (config.nsx_securitygroup?.length) {
            config.servers = [];
        }

        return config;
    }

    /**
     * Returns true if pool is enabled.
     */
    public isEnabled(): boolean {
        return this.getConfig().enabled;
    }

    /**
     * Makes a request to get addresses for a security group.
     */
    public getSecurityGroupAddresses(): IPromise<any> {
        const { nsx_securitygroup: nsxSecurityGroup } = this.getConfig();

        if (!isArray(nsxSecurityGroup) || !nsxSecurityGroup.length) {
            return Promise.resolve([]);
        }

        const cloudId = this.stringService.slug(this.getCloudRef());
        const sg = nsxSecurityGroup[0];
        const api = '/api/nsxt/group/ips';
        const requestConfig = {
            url: api,
            method: HttpMethod.POST,
            data: {
                cloud_uuid: cloudId,
                group_id: sg,
            },
        };

        this.errors = null;

        return this.httpWrapper.request(requestConfig)
            .then(({ data }) => {
                const groupIps = data.resource?.nsxt_group_ips;

                return Array.isArray(groupIps) ? groupIps.map(({ group_ip: ip }) => ({ ip })) : [];
            })
            .catch(({ data }) => {
                this.errors = data;

                return [];
            });
    }

    /**
     * Returns a list of server AutoScale groups.
     */
    public getAutoscaleGroups(): IPromise<IAutoscaleGroupsApiResponse[]> {
        const { cloud_ref: cloudRef } = this.getConfig();
        const cloudId = this.stringService.slug(cloudRef);

        this.busy = true;
        this.errors = null;

        const api = `/api/cloud/${cloudId}/autoscalegroup`;
        const requestConfig = {
            url: api,
            method: HttpMethod.GET,
        };

        return this.httpWrapper.request(requestConfig)
            .then(({ data }) => data.results)
            .catch(() => [])
            .finally(() => this.busy = false);
    }

    /** @override */
    public getMetricsTuple(): IMetricsQuery {
        return {
            entity_uuid: '*',
            aggregate_entity: true,
            pool_uuid: this.id,
        };
    }

    /**
     * Returns Pool's config default_server_port property.
     */
    public getDefaultServerPort(): number | undefined {
        const { default_server_port: defaultServerPort } = this.getConfig() || {};

        return defaultServerPort || undefined;
    }

    /**
     * Is used by JS DeepDiff.diff function as a prefilter for editable objects comparison at
     * the modal windows. By default filters out AngularJS $$hashKey property only.
     */
    public modifiedDiffFilter(path: string[], key: string): boolean {
        const fullPath = path.join('/');

        let res;

        // default check for $$hashKey
        res = super.modifiedDiffFilter(path, key);

        // specific for the Pool modals: servers:[{index:}]
        if (!res) {
            res = key === 'index' && !fullPath;
        }

        return res;
    }

    /**
     * If the Pool state doesn't match the passed in state, toggle it and save the Pool.
     */
    public setEnabledState(enabled = true): IPromise<IHttpResponse<void>> {
        const promise = new Promise<IHttpResponse<void>>(resolve => {
            if (this.isEnabled() !== enabled) {
                resolve(this.patch({ replace: { enabled } }));
            }
        });

        return promise;
    }

    /**
     * @override
     */
    public submit(): IPromise<void> {
        return super.submit()
            .then(() => {
                this.apiResponseCache = { autoscaleGroupServers: {} };
            });
    }

    /**
     * @override
     */
    public dismiss(args: any): void {
        super.dismiss(args);
        this.apiResponseCache = { autoscaleGroupServers: {} };
    }

    /**
     * Returns vs id this pool is used by. For pool details pages it is vsId from $stateParams,
     * otherwise first from the list provided by inventory API.
     */
    public getVSId(): string {
        const { vsId, vs } = this;

        if (vsId) {
            return vsId;
        }

        if (vs) {
            return vs.id;
        }

        const vsRefs = this.getVSRefs();

        return vsRefs.length ? this.stringService.slug(vsRefs[0]) : '';
    }

    /**
     * Returns the list of VS refs this pool is used by. Provided by inventory API, hence
     * present only on Pools belonging to collection.
     */
    public getVSRefs(): string[] {
        const { virtualservices: list } = this.data || {};

        return list ? list.concat() : [];
    }

    /** @override */
    public isEditable(): boolean {
        const { gslb_sp_enabled: gslbSpEnabled } = this.getConfig();

        return !gslbSpEnabled && super.isEditable();
    }

    /** @override */
    public isProtected(): boolean {
        const { gslb_sp_enabled: gslbSpEnabled } = this.getConfig() || {};

        return gslbSpEnabled || super.isProtected();
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public getDetailsPageStateParams_(): IDetailsPageStateParams {
        return {
            poolId: this.id,
            vsId: this.getVSId(),
        };
    }

    /**
     * Checks whether there is at least one Server having VM id within this Pool.
     */
    public hasServerWithVMId(): boolean {
        const { servers } = this.getConfig();

        return some(servers.flattenConfig(), this.Server.getServerVMId);
    }

    /** @override */
    public hasCustomTimeFrameSettings(): boolean {
        const { vs } = this;

        return !this.collection && vs && vs.hasCustomTimeFrameSettings() || false;
    }

    /** @override */
    public getCustomTimeFrameSettings(tfLabel: string): { step: number, limit: number} | null {
        if (this.hasCustomTimeFrameSettings()) {
            return this.vs.getCustomTimeFrameSettings(tfLabel);
        }

        return null;
    }

    /**
     * Returns nested server config by its id.
     */
    public getServerConfigById(serverId: string): IExtendedServer {
        const { servers } = this.getConfig();

        const serverConfig = findWhere(servers.flattenConfig(), { uuid: serverId });

        if (serverConfig) {
            return copy(serverConfig);
        }

        return null;
    }

    /**
     * Returns a list of pool group names.
     */
    public getPoolGroupNames(): string[] {
        // pool_group_refs comes from inventory API
        const { pool_group_refs: refs } = this.data;

        return refs ? refs.map(ref => this.stringService.name(ref)) : [];
    }

    /**
     * Returns a list of servers belonging to an AutoScale group.
     */
    public getAutoscaleGroupServers(): IPromise<IServer[]> {
        const {
            cloud_ref: cloudRef,
            external_autoscale_groups: externalAutoscaleGroups,
        } = this.getConfig();
        const cloudId = this.stringService.slug(cloudRef);
        const names = externalAutoscaleGroups || [];

        const promises = names.map((name: string) => {
            if (name in this.apiResponseCache.autoscaleGroupServers) {
                return Promise.resolve(this.apiResponseCache.autoscaleGroupServers[name]);
            } else {
                const api = `/api/cloud/${cloudId}/autoscalegroup/${name}/servers`;
                const requestConfig = {
                    url: api,
                    method: HttpMethod.GET,
                };

                return this.httpWrapper.request(requestConfig).then(({ data }) => {
                    const servers = data?.results || [];

                    this.apiResponseCache.autoscaleGroupServers[name] = servers;

                    return servers;
                }).catch(({ data }) => {
                    this.errors = data;

                    return [];
                });
            }
        });

        this.busy = true;
        this.errors = null;

        return Promise.all(promises)
            .then((responses: IServer[][]) => {
                return responses.reduce((acc: IServer[], servers: IServer[]) => {
                    acc.push(...servers);

                    return acc;
                }, []);
            })
            .finally(() => this.busy = false);
    }

    /**
     * Back-end can populate servers list by referenced object. Here we check whether this is a
     * case.
     */
    public autoPopulatedServers(): boolean {
        const {
            ipaddrgroup_ref: ipAddrGroupRef,
            external_autoscale_groups: externalAutoscaleGroups,
        } = this.getConfig();

        return Boolean(ipAddrGroupRef || !isEmpty(externalAutoscaleGroups));
    }

    /**
     * Returns ipaddrgroup_ref from config.
     */
    public get ipAddrGroupRef(): string | undefined {
        return this.config.ipaddrgroup_ref;
    }

    /**
     * Returns ipaddrgroup_ref from config.
     */
    public set ipAddrGroupRef(ref: string | undefined) {
        this.config.ipaddrgroup_ref = ref;
    }

    /**
     * Checks whether Pool is using NSX security group for servers placement.
     */
    public hasNsxSecurityGroup(): boolean {
        const { nsx_securitygroup: nsxSecurityGroup } = this.getConfig();

        return Boolean(nsxSecurityGroup?.length);
    }

    /**
     * Returns true if Pool has external AutoScale groups configured.
     */
    public hasAutoscaleGroups(): boolean {
        const { external_autoscale_groups: externalAutoscaleGroups } = this.getConfig();

        return !isEmpty(externalAutoscaleGroups);
    }

    /**
     * Updates pool fail action config based on type provided.
     */
    public onFailActionTypeChange(): void {
        const config = this.getConfig();

        config.fail_action.onTypeChange();
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'fail_action',
            'analytics_policy',
            'conn_pool_properties',
            'server_reselect',
        ];
    }

    /**
     * This function emulates a call to the server and giving the promise
     * It is making multiple calls periodically to continuously update the object
     * that was delivered into resolve
     *
     */
    protected loadRequest(fields : string[]): IPromise<any> {
        const requests = [
            this.loadConfig(fields),
            this.loadEventsAndAlerts(fields),
            this.loadMetrics(fields, undefined, undefined),
        ];

        return Promise.all(requests);
    }

    /**
     * Does actual config transformation.
     */
    // eslint-disable-next-line no-underscore-dangle
    protected transformAfterLoad_(config: IPoolConfig): IPoolConfig {
        if ('servers' in config) {
            const {
                servers,
                default_server_port: defaultServerPort,
            } = config;

            servers.config.forEach(server => {
                server.config.default_server_port = defaultServerPort;
                server.config.uuid = this.Server.getServerUuid(server.flattenConfig());
            });
        }

        return config;
    }

    /** @override */
    protected transformAfterLoad(): void {
        this.transformAfterLoad_(this.getConfig());
    }

    protected beforeEdit(): void {
        const config = this.getConfig();
        const servers = config.servers.config;

        if (this.autoPopulatedServers() || this.hasNsxSecurityGroup()) {
            servers.forEach((server: ServerConfigItem) => {
                server.setAutoPopulatedServerDefaults();
            });
        }

        // If server network_ref is present but name is not (ex. OpenStack or AWS network),
        //  we need to make a request to retrieve the name.

        servers.forEach((server: ServerConfigItem) => {
            server.fetchNetwork(this.getCloudRef());
        });

        if (!config.health_monitor_refs) {
            config.health_monitor_refs = [];
        } else {
            config.health_monitor_refs = config.health_monitor_refs.map((healthMonitor: string) => {
                return { value: healthMonitor };
            }) as any;
        }
    }

    /**
     * Removes unsupported properties from Pool config.
     */
    private removeGenericPoolProperties(config: TItemConfig): void {
        delete config.fail_action;
        delete config.conn_pool_properties;
        delete config.server_reselect;
        delete config.horizon_profile;
        delete config.server_disable_type;
        delete config.rewrite_host_header_to_server_name;
        delete config.use_service_port;
        delete config.use_service_ssl_mode;
        delete config.request_queue_enabled;
        delete config.request_queue_depth;
        delete config.routing_pool;
        delete config.min_health_monitors_up;
        delete config.application_persistence_profile_ref;
        delete config.host_check_enabled;
        delete config.domain_name;
        delete config.rewrite_host_header_to_sni;
        delete config.ignore_server_port;
    }
}

Pool.ajsDependencies = [
    'Server',
    'ConfiguredNetwork',
    'stringService',
    HTTP_WRAPPER_TOKEN,
];
