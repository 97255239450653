/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IHttpResponse } from 'angular';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import {
    ConfiguredNetwork,
    IConfiguredNetworkWithRuntime,
    INetworkConfig,
    INetworkData,
    IStaticIpAddrTemp,
} from 'ajs/modules/network/factories/configured-network.item.factory';

import {
    IContentLibConfig,
    IpAddrType,
    IStaticRoute,
    IvCenterConfiguration,
    IVrfContext,
} from 'generated-types';

import {
    IpAddrPrefixConfigItem,
} from 'ajs/modules/data-model/factories/ip-addr-prefix.config-item.factory';
import { CONFIGURED_NETWORK_ITEM_TOKEN } from 'ajs/modules/network/network.tokens';
import { vCenterConfiguration } from 'object-types';

import {
    findWhere,
    values,
} from 'underscore';

import { parseSubnetBaseIp } from 'ng/modules/avi-forms/utils/subnet-base-ip.parser.utils';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';

const VERIFY_LOGIN_API = '/api/vimgrvcenterruntime/verify/login';
const FETCH_CONTENT_LIBRARY_API = '/api/vimgrvcenterruntime/retrieve/contentlibraries';
const FETCH_VCENTER_NETWORKS_API = '/api/vimgrvcenterruntime/retrieve/portgroups';
const FETCH_VRF_CONTEXT_API = '/api/vrfcontext';

const CLOUD_UUID = 'cloud_uuid';
const NETWORK_URL = '/api/vipgnameinfo';
const NETWORK_OVERRIDE_URL = '/api/network';

/**
 * Type of response returned by VCenterLoginVerify method.
 */
export interface IVcenterDcInfo {
    managed_object_id: string;
    name: string;
}

/**
 * Type of networks list received from the back end.
 */
interface IVcenterPgName {
    managed_object_id: string;
    name: string;
    url?: string;
    uuid: string;
}

/**
 * Type of request params for login request.
 */
interface IVcenterLoginCredentials {
    username: string;
    password: string;
    host: string;
}

/**
 * @description
 *
 *   VcenterConfiguration MessageItem.
 *
 * @author Sarthak kapoor
 */

export type TvcenterLoginCredentials =
 Pick<IvCenterConfiguration, 'username' | 'password' | 'vcenter_url'>;

type TVcenterConfigurationPartial = Omit<IvCenterConfiguration, 'content_lib'>;

interface IVcenterConfig extends TVcenterConfigurationPartial {
    content_lib: MessageItem<IContentLibConfig>
    management_ip_subnet: IpAddrPrefixConfigItem;
}

export class VCenterConfigurationConfigItem extends MessageItem<IVcenterConfig> {
    public static ajsDependencies = [
        CONFIGURED_NETWORK_ITEM_TOKEN,
        HTTP_WRAPPER_TOKEN,
        'secretStubStr',
        'stringService',
    ];

    /**
     * Model value for Ip Subnet to be used in template.
     */
    public selectedSubnet: string;

    /**
     * Parsed value of selected subnet.
     */
    public parsedSelectedSubnet: string;

    /**
     * Stores the list of content libraries fetched from back end.
     */
    public contentLibraries: IContentLibConfig[];

    /**
     * Used as a model value in UI for Network Subnets.
     */
    public networkSubnets: IConfiguredNetworkWithRuntime[];

    /**
     * Stores the list of networks received from back end.
     */
    public networks: IVcenterPgName[];

    /**
     * Flag to show network request failed alert.
     */
    public showFetchNetworkFailedAlert: boolean;

    /**
     * Flag to check if network was saved successfully.
     */
    public isNetworkSaveSuccessful: boolean;

    /**
     * Flag to check if vrfContext save is successful.
     */
    public isVrfContextSuccessful: boolean;

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private httpWrapper: HttpWrapper;

    /**
     * ConfiguredNetwork Instance to set Network config.
     */
    private network: ConfiguredNetwork;

    /**
     * Stores the vrfContext fetched from back end.
     */
    private vrfContext: IVrfContext;

    /**
     * Copy of management_ip_subnet config.
     */
    private managementIpSubnetCopy: IpAddrPrefixConfigItem;

    /**
     * String Service Instance.
     */
    private readonly stringService: StringService;

    /**
     * Flag to check if vcenter network fetch call is in progress.
     */
    private isVCenterNetworkFetchInProgress: boolean;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: vCenterConfiguration,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();

        this.stringService = this.getAjsDependency_('stringService');

        this.showFetchNetworkFailedAlert = false;
    }

    /**
     * Parses the Vrf Context.
     */
    private static parseVrfContext(vrfContext: IVrfContext): IVrfContext {
        let staticRoutes: IStaticRoute[];

        staticRoutes = vrfContext.static_routes;
        staticRoutes = values(staticRoutes);

        if (staticRoutes?.length) {
            vrfContext.static_routes = staticRoutes;

            const staticRoute = staticRoutes[0];

            if (!staticRoute.next_hop.addr) {
                vrfContext.static_routes = undefined;
            } else {
                staticRoute.route_id = staticRoute.route_id || '1';
                staticRoute.next_hop.type = staticRoute.next_hop.type || IpAddrType.V4;

                if (!staticRoute.prefix) {
                    staticRoute.prefix = {
                        ip_addr: {
                            type: IpAddrType.V4,
                            addr: '0.0.0.0',
                        },
                        mask: 0,
                    };
                }
            }
        }

        return vrfContext;
    }

    /**
     * Sets properties like username, password and vcenter_url on config.
     */
    public setVcenterLoginCredentials(loginCredentials: TvcenterLoginCredentials): void {
        const { config } = this;

        const {
            vcenter_url: vcenterUrl,
            username,
            password,
        } = loginCredentials;

        config.vcenter_url = vcenterUrl;
        config.username = username;
        config.password = password;
    }

    /**
     * Resets fields when login credentials are changed.
     */
    public resetOnLoginChange(): void {
        const secretStubStr = this.getAjsDependency_('secretStubStr');
        const { config } = this;

        delete config.datacenter;
        delete config.management_network;

        this.safeSetNewChildByField('management_ip_subnet');

        if (config.password === secretStubStr) {
            delete config.password;
        }
    }

    /**
     * Finds the selected network object from the list of fetched network objects.
     */
    public findNetwork(): IVcenterPgName {
        const {
            config,
            networks,
            stringService,
        } = this;
        const { management_network: managementNetwork } = config;

        const selectedManagementNetwork = findWhere(
            networks, { uuid: stringService.slug(managementNetwork) },
        );

        selectedManagementNetwork.url = managementNetwork;

        return selectedManagementNetwork;
    }

    /**
     * Returns the config of set network object.
     */
    public get networkConfig(): INetworkConfig {
        const { networkData } = this;

        return networkData?.config;
    }

    /**
     * Returns the data object set on network object.
     */
    public get networkData(): INetworkData {
        const { network } = this;

        return network?.data;
    }

    /**
     * Extracts the subnets from discovery object set over network object.
     */
    public get subnets(): string[] {
        const { networkData } = this;

        return networkData?.discovery?.ip_subnet?.map(
            ({ subnet }) => subnet,
        );
    }

    /**
     * Returns the first statiic route of vrfContext object set after fetching data from back end.
     */
    public get staticRoute(): IStaticRoute {
        const { vrfContext } = this;

        if (!vrfContext?.static_routes?.length) {
            vrfContext.static_routes = [{
                next_hop: {
                    addr: '',
                },
            }];
        }

        return vrfContext?.static_routes[0];
    }

    /**
     * Returns the count of static ip ranges entered by the user.
     */
    public get staticIpRangeCount(): number {
        const { networkSubnets } = this;

        if (networkSubnets) {
            return networkSubnets[0].static_ipaddr_tmp.length;
        }

        return 0;
    }

    /**
     * Returns the static_ipaddr_tmp array.
     */
    public get staticIpRanges(): IStaticIpAddrTemp[] {
        const { networkSubnets } = this;

        return networkSubnets ? networkSubnets[0]?.static_ipaddr_tmp : [];
    }

    /**
     * Add a static Ip Range.
     * Invoked on click of add button from UI.
     */
    public addStaticIpRange(): void {
        const { networkSubnets } = this;

        networkSubnets[0].static_ipaddr_tmp.push({
            range: '',
        });
    }

    /**
     * Removes a static Ip range.
     * Invoked on click of delete button from UI.
     */
    public removeStaticIpRange(staticIpRange: IStaticIpAddrTemp): void {
        const { networkSubnets } = this;

        const index = networkSubnets[0].static_ipaddr_tmp.indexOf(staticIpRange);

        networkSubnets[0].static_ipaddr_tmp.splice(index, 1);
    }

    /**
     * Parses the selectedSubnet Model value and stores in parsedSelectedSubnet.
     */
    public parseSelectedSubnet(subnet: string): void {
        this.parsedSelectedSubnet = parseSubnetBaseIp(subnet);
    }

    /**
     * Deletes the selected content lib id.
     */
    public removeSelectedContentLibId(): void {
        const { config } = this;
        const { content_lib: contentLib } = config;

        delete contentLib?.config?.id;
    }

    /**
     * Sets management_ip_subnet field on vcenter config object.
     */
    public setManagementIpSubnet(): void {
        const {
            config,
            networkConfig,
            parsedSelectedSubnet,
        } = this;

        if (config.management_ip_subnet) {
            this.managementIpSubnetCopy = config.management_ip_subnet;
        }

        // In case of an error while saving, we'll need a copy of management_ip_subnet.
        const { config: managementIpSubnetConfig } = config?.management_ip_subnet ||
            this.managementIpSubnetCopy;
        const { flatProps } = managementIpSubnetConfig;

        if (networkConfig.dhcp_enabled) {
            delete config.management_ip_subnet;
        } else {
            const parsedSubnetChunks = parsedSelectedSubnet.split('/');

            if (parsedSubnetChunks?.length === 2) {
                flatProps.ip_addr.addr = parsedSubnetChunks[0];
                flatProps.ip_addr.type = IpAddrType.V4;
                flatProps.mask = +parsedSubnetChunks[1];
            } else {
                delete config.management_ip_subnet;
            }
        }
    }

    /**
     * Called to verify VCenter Login Credentials.
     * Also, fetches the list of datacenters.
     */
    public async vcenterLoginVerify(
        cloudId: string,
        loginCredentials?: TvcenterLoginCredentials,
    ): Promise<IVcenterDcInfo[]> {
        this.busy = true;
        this.errors = null;

        const payload = {
            ...this.loginRequestPayload(cloudId, loginCredentials),
        };

        try {
            const requestConfig = {
                url: VERIFY_LOGIN_API,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);

            const { resource: { vcenter_dc_info: vCenterDcInfo } } = response.data;

            if (vCenterDcInfo.length === 1) {
                const { config } = this;

                config.datacenter = vCenterDcInfo[0].name;
            }

            return vCenterDcInfo;
        } catch (error) {
            return Promise.reject(error);
        } finally {
            this.busy = false;
        }
    }

    /**
     * Fetches list of VCenter Content libraries.
     */
    public async fetchContentLibraries(cloudId?: string): Promise<IContentLibConfig[]> {
        this.busy = true;
        this.errors = null;

        const { config } = this;
        const { config: contentLibConfig = {} } = config.content_lib;

        const {
            username,
            password,
            vcenter_url: host,
        } = config;

        const payload = {
            ...this.loginRequestPayload(
                cloudId,
                {
                    username,
                    password,
                    vcenter_url: host,
                },
            ),
        };

        try {
            const requestConfig = {
                url: FETCH_CONTENT_LIBRARY_API,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);

            const { vcenter_clibs: vcenterClibs = [] } = response.data.resource;

            this.contentLibraries = [...vcenterClibs];

            /**
             * We check if the selected content lib is not present in the list of
             * content libraries fetched.
             * If not present, we reset the selected values.
             */
            if (contentLibConfig?.id) {
                const selectedContentLib = this.getSelectedContentLib();

                if (!selectedContentLib) {
                    delete contentLibConfig.id;
                }
            }

            return vcenterClibs;
        } catch (error) {
            this.errors = error.data.error;
        } finally {
            /**
             * In edit flow when all the api calls are in progress simultaneously,
             * finally of content libraries is executed even if fetch networks call is in progress.
             * This results in loader not getting displayed while
             * networks call is still in progress.
             */
            this.busy = this.isVCenterNetworkFetchInProgress;
        }
    }

    /**
     * Fetches VCenter Networks and VrfContext.
     */
    public async fetchVCenterNetworks(
        cloudId: string,
        cloudUrl: string,
        isDhcpEnabled: boolean,
    ): Promise<IVcenterPgName[]> {
        this.busy = true;
        this.errors = null;
        this.showFetchNetworkFailedAlert = false;
        this.isVCenterNetworkFetchInProgress = true;

        const {
            config,
            network,
        } = this;
        const { management_network: managementNetwork } = config;

        const payload = { ...this.networkRequestPayload(cloudId) };

        try {
            const requestConfig = {
                url: `${FETCH_VCENTER_NETWORKS_API}?page_size=200`,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);

            const { vcenter_pg_names: vcenterPgNames = [] } = response.data.resource;

            this.networks = [...vcenterPgNames];

            await this.getVRFContext(cloudId);

            if (managementNetwork && !network) {
                await this.setNetwork(
                    {
                        url: managementNetwork,
                    },
                    cloudUrl,
                    isDhcpEnabled,
                );
            }

            return vcenterPgNames;
        } catch (error) {
            this.showFetchNetworkFailedAlert = true;
        } finally {
            this.isVCenterNetworkFetchInProgress = false;
            this.busy = false;
        }
    }

    /**
     * Called after management network selection (by user from UI or on initial Modal's load).
     */
    public async setNetwork(
        network: INetworkConfig,
        cloudUrl: string,
        isDhcpEnabled: boolean,
    ): Promise<void> {
        const ConfiguredNetwork = this.getAjsDependency_(CONFIGURED_NETWORK_ITEM_TOKEN);
        const {
            config,
            stringService,
        } = this;

        if (!network.url) {
            network.url = `${NETWORK_URL}/${network.uuid}`;
        }

        config.management_network = network.url;

        if (this.network) {
            this.network.destroy();
        }

        this.network = new ConfiguredNetwork({
            id: stringService.slug(network.url),
        });

        if (this.parsedSelectedSubnet) {
            this.parsedSelectedSubnet = '';
            this.selectedSubnet = '';
        }

        try {
            this.errors = null;
            this.busy = true;

            await this.network.load([''], true);

            this.setNetworkSubnet();
        } catch (error) {
            network.dhcp_enabled = true;
            network.overrideURL = NETWORK_OVERRIDE_URL;
            network.cloud_ref = cloudUrl;
            network.dhcp_enabled = isDhcpEnabled;
            this.networkSubnets = [
                {
                    static_ipaddr_tmp: [{
                        range: '',
                    }],
                },
            ];
            network.configured_subnets = this.networkSubnets;
            this.network.data.config = network;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Invoked on click of save from UI.
     * Invokes saveNetwork method to save Network object.
     */
    public async saveNetworkInfo(): Promise<void | IHttpResponse<INetworkConfig>> {
        this.busy = true;
        this.errors = null;
        this.isNetworkSaveSuccessful = false;

        try {
            const promise = await this.saveNetwork();

            this.isNetworkSaveSuccessful = true;

            return promise;
        } catch (error) {
            this.errors = error.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Saves the vrfContext Object.
     */
    public async saveVrfContext(mvrf: IVrfContext): Promise<void> {
        this.busy = true;
        this.errors = null;
        this.isVrfContextSuccessful = false;

        let { vrfContext } = this;

        if (!vrfContext && mvrf?.uuid) {
            vrfContext = mvrf;
        }

        if (vrfContext?.url) {
            const parsedVrfContext = VCenterConfigurationConfigItem.parseVrfContext(vrfContext);

            try {
                const requestConfig = {
                    url: vrfContext.url,
                    method: HttpMethod.PUT,
                    data: parsedVrfContext,
                };

                const response = await this.httpWrapper.request(requestConfig);

                this.vrfContext = response.data;
                this.isVrfContextSuccessful = true;
            } catch (error) {
                this.errors = error.data;
            } finally {
                this.busy = false;
            }
        } else {
            Promise.reject();
        }
    }

    /**
     * @override
     */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { config } = this;

        if (!config.content_lib) {
            this.safeSetNewChildByField('content_lib');
        }

        if (!config.management_ip_subnet) {
            this.safeSetNewChildByField('management_ip_subnet');
        }
    }

    /**
     * Saves the network object.
     */
    private async saveNetwork(): Promise<void | IHttpResponse<INetworkConfig>> {
        this.busy = true;
        this.errors = null;

        const { network } = this;
        const {
            dhcp_enabled: dhcpEnabled,
            ip6_autocfg_enabled: ip6AutoCfgEnabled,
        } = network.data.config;

        this.copyParsedSelectedSubnetToNetwork();

        try {
            return await this.network.save();
        } catch (error) {
            /**
             * When we save Vcenter Cloud for the first time, vcenter cloud connector returns
             * the list of networks from their end while the webapp starts with the network
             * discovery process behind the scenes. In the process of this discovery there is a
             * possible scenario where the network we select from the UI and make changes to,
             * gets updated at the back end which UI is not aware of.
             * In this case when we do a PUT on the selected network object,
             * we get a concurrent update error from the back end.
             * This code ensures in such a scenario we reload the network object, patch whatever
             * values user has selected in the UI to this updated network object and again try
             * for a PUT.
             */
            if (error.status === 409 || error.status === 412) {
                return this.network.load([''], true).then(
                    () => {
                        this.network.data.config.dhcp_enabled = dhcpEnabled;
                        this.network.data.config.ip6_autocfg_enabled = ip6AutoCfgEnabled;
                        this.copyStaticAddrToNetwork();

                        return this.saveNetwork();
                    },
                );
            } else {
                return Promise.reject(error);
            }
        }
    }

    /**
     * Requests VRF management context based on cloud Id.
     */
    private async getVRFContext(cloudId: string): Promise<void> {
        if (cloudId) {
            try {
                const url = `${FETCH_VRF_CONTEXT_API}?name=management&cloud_uuid=${cloudId}`;

                const requestConfig = {
                    url,
                    method: HttpMethod.GET,
                };

                const response = await this.httpWrapper.request(requestConfig);

                const { data } = response;

                if (data && data.results && data.results.length) {
                    [this.vrfContext] = data.results;

                    if (!this.vrfContext.static_routes || !this.vrfContext.static_routes.length) {
                        this.vrfContext.static_routes = [{
                            next_hop: {
                                addr: '',
                            },
                        }];
                    }
                }
            } catch (error) {
                this.errors = error.data;
            }
        } else {
            Promise.reject();
        }
    }

    /**
     * Sets vCenter cloud's subnet after selecting and loading of vCenter management network.
     */
    private setNetworkSubnet(): void {
        if (this.network) {
            this.network.beforeEdit();

            // Make sure subnet and add pool are defined.
            const { config: netConfig } = this.network.data;

            this.networkSubnets = netConfig.configured_subnets;

            if (this.networkSubnets.length) {
                // First one should be selected by default.
                this.parsedSelectedSubnet = this.networkSubnets[0].subnet;
                this.selectedSubnet = this.parsedSelectedSubnet;

                if (
                    Array.isArray(this.networkSubnets[0].static_ipaddr_tmp) &&
                    !this.networkSubnets[0].static_ipaddr_tmp.length
                ) {
                    this.networkSubnets[0].static_ipaddr_tmp.push({
                        range: '',
                    });
                }
            } else {
                this.networkSubnets[0] = {
                    static_ipaddr_tmp: [{
                        range: '',
                    }],
                };

                this.parsedSelectedSubnet = '';
                this.selectedSubnet = '';
            }
        }
    }

    /**
     * Returns if the password field was reset or changed.
     */
    private passwordChanged(password: string): boolean {
        const secretStubStr = this.getAjsDependency_('secretStubStr');

        return password !== secretStubStr;
    }

    /**
     * Returns the payload for login and content library api calls.
     */
    private loginRequestPayload(
        cloudId: string,
        loginCredentials?: TvcenterLoginCredentials,
    ): IVcenterLoginCredentials | Record<string, string> {
        const payload: IVcenterLoginCredentials | Record<string, string> = {};

        if (!loginCredentials) {
            payload[CLOUD_UUID] = cloudId;
        } else if (this.passwordChanged(loginCredentials?.password)) {
            const {
                username,
                password,
                vcenter_url: vcenterUrl,
            } = loginCredentials;

            payload.username = username;
            payload.password = password;
            payload.host = vcenterUrl;
        } else {
            payload[CLOUD_UUID] = cloudId;
        }

        return payload;
    }

    /**
     * Returns the payload for fetch network call.
     */
    private networkRequestPayload(
        cloudId: string,
    ): TvcenterLoginCredentials | Record<string, string> {
        const payload: TvcenterLoginCredentials | Record<string, string> = {};

        const { config } = this;
        const {
            username,
            password,
            vcenter_url: vcenterUrl,
        } = config;

        payload[CLOUD_UUID] = cloudId;

        if (this.passwordChanged(password)) {
            payload.username = username;
            payload.password = password;
            payload.vcenter_url = vcenterUrl;
        }

        return payload;
    }

    /**
     * Returns the selected content lib object.
     */
    private getSelectedContentLib(): IContentLibConfig | undefined {
        const {
            config,
            contentLibraries,
        } = this;
        const { config: contentLibConfig = {} } = config.content_lib;

        return findWhere(contentLibraries, { id: contentLibConfig?.id });
    }

    /**
     * Copies the parsed value of selected subnet to Network config.
     */
    private copyParsedSelectedSubnetToNetwork(): void {
        const {
            networkConfig,
            parsedSelectedSubnet,
        } = this;

        if (!networkConfig.configured_subnets?.length) {
            networkConfig.configured_subnets = [{}];
        }

        if (parsedSelectedSubnet) {
            networkConfig.configured_subnets[0].subnet = parsedSelectedSubnet;
        }
    }

    /**
     * Copies entered Static Ip Addresses to network object.
     */
    private copyStaticAddrToNetwork(): void {
        const {
            networkConfig,
            networkSubnets,
        } = this;
        const { static_ipaddr_tmp: staticIpAddrTemp } = networkSubnets[0];

        if (networkConfig.dhcp_enabled) {
            return;
        }

        if (networkSubnets?.length) {
            if (!networkConfig.configured_subnets) {
                networkConfig.configured_subnets = [{}];
            }

            networkConfig.configured_subnets[0].static_ipaddr_tmp = staticIpAddrTemp;
        }
    }
}
