/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'network-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const excludedLabel = `${prefix}.excludedLabel`;
export const configuredSubnetsValueText = `${prefix}.configuredSubnetsValueText`;
export const columnTitleDiscoveredSubnets = `${prefix}.columnTitleDiscoveredSubnets`;
export const columnTitleConfiguredSubnets = `${prefix}.columnTitleConfiguredSubnets`;
export const columnTitleStaticIpPools = `${prefix}.columnTitleStaticIpPools`;
export const columnTitleVrfContext = `${prefix}.columnTitleVrfContext`;

export const ENGLISH = {
    [headerLabel]: 'Network Profiles',
    [excludedLabel]: 'Excluded',
    [configuredSubnetsValueText]: '{0} (+{1} More)',
    [columnTitleDiscoveredSubnets]: 'Discovered Subnets',
    [columnTitleConfiguredSubnets]: 'Configured Subnets',
    [columnTitleStaticIpPools]: 'Static IP Pools',
    [columnTitleVrfContext]: 'VRF Context',
};
