/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { EmailConfiguration } from 'object-types';

import {
    IEmailConfiguration,
    SMTPType,
} from 'generated-types';

import './email-smtp-config.component.less';
import * as l10n from './email-smtp-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

@Component({
    selector: 'email-smtp-config',
    templateUrl: './email-smtp-config.component.html',
})
export class EmailSmtpConfigComponent {
    /**
     * Email SMTP Config.
     */
    @Input()
    public emailSmtpConfig: IEmailConfiguration;

    /**
     * Use this as default state when email type is changed.
     */
    @Input()
    public defaultEmailSmtpConfig: IEmailConfiguration;

    /**
     * SMTP type flags, for template usage.
     */
    public readonly emailSmtpType = {
        isSmtpTypeServer: false,
        isSmtpTypeAnonymousServer: false,
        isSmtpTypeNone: false,
        isSmtpTypeLocal: false,
    };

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Object type for tempate usage.
     */
    public readonly objectType = EmailConfiguration;

    /**
     * SMTP Types for template usage.
     */
    public readonly smtpType = {
        [SMTPType.SMTP_ANONYMOUS_SERVER]: SMTPType.SMTP_ANONYMOUS_SERVER,
        [SMTPType.SMTP_LOCAL_HOST]: SMTPType.SMTP_LOCAL_HOST,
        [SMTPType.SMTP_NONE]: SMTPType.SMTP_NONE,
        [SMTPType.SMTP_SERVER]: SMTPType.SMTP_SERVER,
    };

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Setting smtp type flags.
     */
    public ngOnInit(): void {
        this.setSmtpTypeFlags();
    }

    /**
     * Settings SMTP type flags for template usage.
     */
    public setSmtpTypeFlags(): void {
        this.emailSmtpType.isSmtpTypeServer =
            this.emailSmtpConfig.smtp_type === this.smtpType.SMTP_SERVER;
        this.emailSmtpType.isSmtpTypeAnonymousServer =
            this.emailSmtpConfig.smtp_type === this.smtpType.SMTP_ANONYMOUS_SERVER;
        this.emailSmtpType.isSmtpTypeNone =
            this.emailSmtpConfig.smtp_type === this.smtpType.SMTP_NONE;
        this.emailSmtpType.isSmtpTypeLocal =
            this.emailSmtpConfig.smtp_type === this.smtpType.SMTP_LOCAL_HOST;
    }

    /**
     * Set SMTP type flags, and all email config fields to their default values.
     */
    public onSmtpTypeChange(): void {
        this.setSmtpTypeFlags();

        Object.assign(this.emailSmtpConfig, this.defaultEmailSmtpConfig);
    }
}
